<template>
    <v-container>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton"></v-skeleton-loader>
        <v-row>
            <v-col>

                <v-data-table
                :search="this.searchData"
                :items="this.filteredData"
                :headers="this.dataHeader"
                class="elevation-1"
                dense v-if="this.data!=null && !api.isLoading">
    
                    <template v-slot:item.created_at="{item}">
                        <span>
                            {{ convertTimeZone(item.created_at) }}
                        </span>
                    </template>
                    <template v-slot:item.company_name="{item}">
                        <router-link :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                            {{ item.company_name }}
                        </router-link>
                    </template>
                    <template v-slot:item.Index="{item}">
                        {{ data.indexOf(item) +1 }}
                    </template>
                    <template v-slot:item.invoice_expired_at="{item}">
                        {{ convertTimeZone(item.invoice_expired_at) }}
                    </template>

                    <template v-slot:top>
                             <v-toolbar height="auto" width="auto" class="py-3" flat>
                                <v-row no-gutters>
                                    <v-col class="mt-3 mx-3">
                                        <v-row>
                                            <v-toolbar-title class="font-weight-bold text-h4 mt-3 mb-6 ml-5">
                                                 Companies
                                            </v-toolbar-title>
    
                                        </v-row>

                                        
                                        <v-row>
                                            <v-col class="ml-5">
                                                <div class="d-flex justify-start  ">
                                                    <span class="tet-h7 grey--text">
                                                        Subscription Date :
                                                    </span>
                                                </div>
                                                <v-row>
                                                    <v-col class="px-0">

                                                        <v-text-field style="border-radius:4px 0px 0px 4px" dense outlined 
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isStartDate = true;
                                                        }"
                                                        @click="()=>{
                                                            isStartDate =true;
                                                        }"
                                                        label="Start Date..."
                                                        v-model = "startDate">

                                                        </v-text-field>

                                                        <v-dialog v-model="isStartDate" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                                <div class="d-flex flex-column">
                                                                    <p>
                                                                        Start Date
                                                                    </p>
                                                                    <div>

                                                                        <v-date-picker flat v-model="startDate" :landscape="true"
                                                                        :reactive="true">
                                                                        </v-date-picker>

                                                                        <slot name="footer">
                                                                            <div class="d-flex justify-end white" >
                                                                                <v-btn color="primary" plain @click="()=>{
                                                                                    isStartDate=false;
                                                                                    startDate=null;
                                                                                }">
                                                                                    Cancel

                                                                                </v-btn>
                                                                                <v-btn color="primary" @click="()=>{
                                                                                    isStartDate = false;
                                                                                }">
                                                                                    Confirm
                                                                                </v-btn>
                                                                            </div>
                                                                        </slot>
                                                                    </div>
                                                                </div>
                                                            </v-card>
                                                            

                                                        </v-dialog>


                                                    </v-col>
                                                    <v-col class="px-0">
                                                        <v-text-field dense outlined  style="border-radius:0px"
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isEndDate = true;
                                                        }"
                                                        @click="()=>{
                                                        isEndDate =true;
                                                        }"
                                                        label="End Date..."
                                                        v-model = "endDate">

                                                        </v-text-field>

                                                        <v-dialog v-model="isEndDate" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                            <div class="d-flex flex-column">
                                                                <p>
                                                                    End Date
                                                                </p>
                                                                <div>
                                                                    <v-date-picker flat v-model="endDate" :landscape="true"
                                                                    :reactive="true">
                                                                    </v-date-picker>
                                                                </div>
                                                            </div>
                                                            <div name="footer">
                                                                <div class="d-flex justify-end my-3">
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isEndDate = false;
                                                                        endDate=null;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isEndDate = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                            
                                                        </v-dialog>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col class=" mr-6 ">
                                                <div class="d-flex justify-start ">
                                                    <span class="text-h7 grey--text">
                                                        Expired Date :

                                                    </span>
                                                </div>
                                                <v-row>
                                                    <v-col class="px-0">

                                                        <v-text-field style="border-radius:0px" dense outlined 
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isStartDateExpired = true;
                                                        }"
                                                        @click="()=>{
                                                            isStartDateExpired =true;
                                                        }"
                                                        label="Start Date..."
                                                        v-model = "startDateExpired">
                            
                                                        </v-text-field>
                            
                                                        <v-dialog v-model="isStartDateExpired" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                                <div class="d-flex flex-column">
                                                                    <p>
                                                                        Start Date
                                                                    </p>
                                                                    <div>
            
                                                                        <v-date-picker flat v-model="startDateExpired" :landscape="true"
                                                                        :reactive="true">
                                                                        </v-date-picker>
            
                                                                        <slot name="footer">
                                                                            <div class="d-flex justify-end white" >
                                                                                <v-btn color="primary" plain @click="()=>{
                                                                                    isStartDateExpired=false;
                                                                                    startDate=null;
                                                                                }">
                                                                                    Cancel
            
                                                                                </v-btn>
                                                                                <v-btn color="primary" @click="()=>{
                                                                                    isStartDateExpired = false;
                                                                                }">
                                                                                    Confirm
                                                                                </v-btn>
                                                                            </div>
                                                                        </slot>
                                                                    </div>
                                                                </div>
                                                            </v-card>
                                                            
                                                        
                                                        </v-dialog>
                                                    </v-col>
                                                    <v-col class="px-0">

                                                        <v-text-field dense outlined  style="border-radius:0px 4px 4px 0px"
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click:prepend-inner="()=>{
                                                            isEndDateExpired = true;
                                                        }"
                                                        @click="()=>{
                                                        isEndDateExpired =true;
                                                        }"
                                                        label="End Date..."
                                                        v-model = "endDateExpired">
                            
                                                        </v-text-field>
                                                       
                                                        <v-dialog v-model="isEndDateExpired" height="auto" width="auto">
                                                            <v-card class="pa-3">
                                                            <div class="d-flex flex-column">
                                                                <p>
                                                                    End Date
                                                                </p>
                                                                <div>
                                                                    <v-date-picker flat v-model="endDateExpired" :landscape="true"
                                                                    :reactive="true">
                                                                    </v-date-picker>
                                                                </div>
                                                            </div>
                                                            <div name="footer">
                                                                <div class="d-flex justify-end my-3">
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isEndDateExpired = false;
                                                                        endDateExpired=null;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isEndDateExpired = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                           
                                                        </v-dialog>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <div class="d-flex justify-end mr-6 mb-6 pb-6">
                                            <div class="px-2"> 
                                                <v-btn color="primary" outlined @click="resetFilter" >
                                                    Reset Filter
                                                </v-btn>

                                            </div>
                                            <v-btn color="primary" @click="filterDate">
                                                Filter
                                            </v-btn>
                                        </div>
                                        <v-col class="mt-5">
                                            <v-text-field v-model="searchData" dense outlined label="Search...">
    
                                            </v-text-field>
                                        </v-col>
                                        <div class="d-flex justify-end">
                                            <!-- <v-btn color="primary" class="mx-2" @click="resetFilter" outlined>
                                                Reset Filter
                                            </v-btn> -->
                                            <JsonCSV :data="downloadData">
                                                <v-btn color="primary">
                                                    Download
                                                </v-btn>
                                            </JsonCSV>
                                        </div>
                                    </v-col>
                                </v-row>
                                
                             </v-toolbar>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default{
    data:()=>({
        data:null,
        filteredData:null,
        isStartDate :false,
        searchData:null,
        downloadData:null,
        isEndDate:false,
        startDate:null,
        endDate:null,
        startDateExpired:null,
        isStartDateExpired:false,
        endDateExpired:null,
        isEndDateExpired:false,
        dataHeader:[
            {
                text:'#',
                value:'Index',
                align:'start',
                sortable:false,
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Subscription Date',
                value:'created_at'
            },
            {
                text:'Expired Date',
                value:'invoice_expired_at',
            },
        ],
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created(){
        this.api.callbackReset = () => {
                this.api.isLoading = true;
                this.api.isError = false;
            };
            this.api.callbackError = (e) => {
                this.api.isLoading = false;
                this.api.isError = true;
                this.api.error = e;
            };
            this.api.callbackSuccess = (resp) => {
              if(resp.class==="getUnassignedCompany"){
                    this.data =resp.data;
                    this.filteredData = resp.data;
                    this.downloadData = resp.data.map(obj=>({
                        "Company":obj.company_name,
                        "Subscription Date":this.convertTimeZone(obj.created_at),
                    }));
              }
              this.api.isLoading = false;
              this.api.isError = false
            };
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            let fetchUnassignedCompaniesApi = this.fetchUnassignedCompanies();
            this.$api.fetch(fetchUnassignedCompaniesApi);
        },
        fetchUnassignedCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/report/pic/company/unassigned";
            return tempApi;
        },
        filterDate(){
            let tempData = this.$_.clone(this.data);
            let tempStartDate = this.$_.clone(this.startDate);
            let tempEndDate = this.$_.clone(this.endDate);

            if(tempStartDate!=null){

                tempData =  tempData.filter(function(item){
                    let tempCreatedAt = item.created_at.split(" ")[0];
                    if(tempCreatedAt>=tempStartDate){
                        return true;
                    }
                })
            }


            if(tempEndDate!=null){
                tempData = tempData.filter(function(item){
                    let tempCreatedAt = item.created_at.split(" ")[0];
                  
                    if(tempCreatedAt<=tempEndDate){
                        return true;
                    }
                })
            }


            let tempStartDateExpired = this.$_.clone(this.startDateExpired);
            let tempEndDateExpired = this.$_.clone(this.endDateExpired);

            if(tempStartDateExpired!=null){

                tempData =  tempData.filter(function(item){
                    let tempCreatedAt = item.invoice_expired_at.split(" ")[0];
                    if(tempCreatedAt>=tempStartDateExpired){
                        return true;
                    }
                })
            }


            if(tempEndDateExpired!=null){
                tempData = tempData.filter(function(item){
                    let tempCreatedAt = item.invoice_expired_at.split(" ")[0];
                  
                    if(tempCreatedAt<=tempEndDateExpired){
                        return true;
                    }
                })
            }

            

            this.filteredData = tempData;
            this.downloadData = this.filteredData.map(obj=>({
                        "Company":obj.company_name,
                        "Subscription Date":this.convertTimeZone(obj.created_at),
                    }));

        },
        convertTimeZone(time){
            return this.$moment(time).format("LLL");
        },
        resetFilter(){
            this.filteredData = this.data;
            this.startDate = null;
            this.endDate = null;
            this.searchData = null;
            this.downloadData = this.filteredData.map(obj=>({
                        "Company":obj.company_name,
                        "Subscription Date":this.convertTimeZone(obj.created_at),
                    }));

        }
    },
}
</script>